<template>
    <div>
        <div>
            <table width="100%" border="0">
                <tr>
                    <td colspan="5" style="
                        height: 10px;
                        background-image: linear-gradient(112.07deg, #009FC2 11.98%, #28AE96 74.26%);
                        box-shadow: 0px -74px 200px rgba(0, 0, 0, 0.15);
                        border-radius: 0px;
                    "></td>
                </tr>
                <tr>
                    <td style="width: 40%;">
                        <img src="../../../../../assets/images/erajaya.png" alt="logo.png" style="
                            /* position: absolute; */
                            width: 20px;
                            margin-top: 24px;
                            margin-left: 60px;
                        ">
                    </td>
                    <td colspan="4">
                        <div class="title" style="
                            margin-top: 24px;
                            font-weight: 800;
                            color: black;
                        ">Erajaya Group Companies</div>
                        <div class="address" style="
                            margin-top: 10px;
                        ">
                            <p style="color: #555;">
                                Jl. Pegangsaan Indah Barat V Blok I No. 9 Kelapa Gading <br>
                                Jakarta Utara 14250 Indonesia <br>
                                Tel: +62 021 2938 2738
                            </p>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" style="
                        width: 55%;
                        height: 36px;
                        background-image: linear-gradient(112.07deg, #009FC2 11.98%, #28AE96 74.26%);
                        box-shadow: 0px -74px 200px rgba(0, 0, 0, 0.15);
                        border-radius: 0px;
                    "></td>
                    <td colspan="2" style="
                        height: 36px;
                        background: transparent;
                        box-shadow: none;
                        border-radius: 0px;
                        text-align: center;
                    ">
                        <span style="font-size: 30px; font-weight: 900; color: #555;">DELIVERY EXPENSE</span>
                    </td>
                    <td style="
                        width: 5%;
                        height: 36px;
                        background-image: linear-gradient(112.07deg, #009FC2 11.98%, #28AE96 74.26%);
                        box-shadow: 0px -74px 200px rgba(0, 0, 0, 0.15);
                        border-radius: 0px;
                    "></td>
                </tr>
                <tr>
                    <td style="padding: 2rem; color: #555;">
                        Kepada, <br>
                        <b style="color: black">PT RLC Indonesia</b><br>
                        Jl. babeka Park 3, Blok B3 A1 Jababeka <br>
                        Phase VII, Kel Wangunharja <br>
                        Kec. Cikarang Utara
                    </td>
                    <td></td>
                    <td colspan="4">
                        <table style="color: #555; padding-left: 15px;">
                            <tr>
                                <td style="width: 40%;">DE. No</td>
                                <td>:</td>
                                <td class="pl-4"><b>J01045/OPL-292</b></td>
                            </tr>
                            <tr>
                                <td style="width: 40%;">Date</td>
                                <td>:</td>
                                <td class="pl-4">10 Maret 2022</td>
                            </tr>
                            <tr>
                                <td style="width: 40%;">B/L No</td>
                                <td>:</td>
                                <td class="pl-4"><b>SITGSHJTQ20105</b></td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr style="
                    background-color: #f8f8f8;
                ">
                    <td colspan="5" style="padding: 1rem;">
                        <table border="0" width="100%">
                            <tr>
                                <td>
                                    <img src="../../../../../assets/images/delivery-truck.png" alt=""
                                        style="width: 50px;">
                                </td>
                                <td>
                                    <b>Truck Box</b><br>
                                    B 123 UIX
                                </td>
                                <td>
                                    Seal No : <b>001</b>
                                </td>
                                <td>
                                    Customer No : <b>001</b>
                                </td>
                                <td>
                                    Driver Name : <b>Dwi</b>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td colspan="5">
                        <table width="100%" style="
                            padding: 1rem; 
                            text-align: center;
                            border-color: #DEDEDE;
                        " border="0">
                            <tr style="
                                background-image: linear-gradient(112.07deg, #009FC2 11.98%, #28AE96 74.26%);
                                color: white;
                                text-align: center;
                            ">
                                <td style="padding: 1rem;">No</td>
                                <td style="padding: 1rem;">Item</td>
                                <td style="padding: 1rem;">Measurment</td>
                                <td style="padding: 1rem;">Weight</td>
                                <td style="padding: 1rem;">Qty</td>
                                <td style="padding: 1rem;">Unloading Delivery</td>
                            </tr>
                            <tr>
                                <td style="padding: 1rem;">1</td>
                                <td style="padding: 1rem;">Item A</td>
                                <td style="padding: 1rem;">10.000 CBM</td>
                                <td style="padding: 1rem;">200 KGS</td>
                                <td style="padding: 1rem;">30</td>
                                <td style="padding: 1rem;">Place 3</td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td colspan="5" style="
                        margin: 30px 1.5% 0 1.5%;
                        background-color: #F8F8F8;
                        border-style: dashed;
                        border-width: 2px;
                        border-radius: 3px;
                        border-color: #D2D2D2;
                        padding: 2rem;
                    ">
                        <b>Notes : </b>
                        <br>
                        <ul style="color: #555">
                            <li>Barang diterima dalam keadaan original packing, baik & lengkap</li>
                            <li>Kami hanya menerima complain dalam waktu 1x24 jam</li>
                            <li>Kami tidak menanggung segala bentuk kerugian, kerusakan serta <br> kehilangan yang
                                ditimbulkan karena <b><i>force majure</i></b></li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td colspan="5">
                        <table width="100%" style="
                            border: none;
                            text-align: center;
                            margin-top: 100px;
                            
                        ">
                            <tr>
                                <th>Diterima Oleh,</th>
                                <th>Expedisi,</th>
                                <th>Dikirim Oleh,</th>
                            </tr>
                            <tr>
                                <th>
                                    <hr style="border-color: black; width: 150px; margin-top: 100px;">
                                </th>
                                <th>
                                    <hr style="border-color: black; width: 150px; margin-top: 100px;">
                                </th>
                                <th>
                                    <hr style="border-color: black; width: 150px; margin-top: 100px;">
                                </th>
                            </tr>
                            <tr>
                                <td>Septio Dwi</td>
                                <td>Gilang</td>
                                <td>Dwira Naufal</td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        </div>

    </div>


</template>

<script>

export default {
    components: {

    },
    methods: {

    },
}
</script>

<style>
/* body{
        background-color: white;
        color: #000;
    }
    .del_ex{
        font-weight: 900;
    } */
</style>